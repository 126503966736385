import {Swiper, Navigation} from 'swiper';

jQuery(document).ready(function ($) {
    Swiper.use(Navigation)
    let logoSwiper = new Swiper('.b-partners .swiper-container', {
        slidesPerView: 1.3,
        spaceBetween: 24,
        breakpoints: {
            768: {
                slidesPerView: 4,
            },
        }
    });

    $('.swiper-container.c-gallery').each(function(){
        let gallerySwiper = new Swiper(this, {
        effect: 'fade',
        });

        const swiper = this.swiper;

        $(this).parent().find('.swiper-button-prev').on('click', function(e){
          e.preventDefault();
          swiper.slidePrev();
        });

        $(this).parent().find('.swiper-button-next').on('click', function(e){
          e.preventDefault();
          swiper.slideNext();
        });
    });
});


jQuery(document).ready(function($) {
    $('.c-slide__image').on('mouseover', function() {
        $('.c-slide__text').removeClass('c-slide__text--active');
        $(this).parent().find('.c-slide__text').addClass('c-slide__text--active');
    });
});