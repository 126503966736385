import 'waypoints/lib/noframework.waypoints.min';
import 'waypoints/lib/shortcuts/inview.min';

jQuery(document).ready(function($) {
    if($('.b-sections__menu').length > 0) {
        let containerOffset = Math.ceil($('.b-sections').offset().top);
        let wp = [];
        let sections = $('section[data-section]');
        let items = $('.b-sections__menu-item');

        if(containerOffset < 1680) {
            containerOffset = containerOffset - 117;
        }

        $(window).on('scroll',function(e){
            let currentOffset = $(document).scrollTop();
            if(currentOffset > containerOffset) {
                $('.b-sections').addClass('sticky');
            }else {
                $('.b-sections').removeClass('sticky');
            }
        });

        $(sections).each(function(key, item){
            wp.push(new Waypoint({
                element:item,
                handler: function (direction) {
                    if(direction == 'up') {
                        if(key > 0){
                            items[key].classList.remove('active');
                            items[key - 1].classList.add('active');
                        }
                    }else {
                        if((key) < items.length){
                            items[key].classList.add('active');
                            if((key - 1) >= 0){
                                items[key - 1].classList.remove('active');
                            }
                        }
                    }
                },
                offset: '250',
            }));
        })
        Waypoint.refreshAll();
    }
});