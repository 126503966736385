"use strict";

import mixitup from 'mixitup';
import mixitupMultifilter from '../optional/mixitup-multifilter.min.js';
import mixitupPagination from '../optional/mixitup-pagination.min.js';


mixitup.use(mixitupPagination);
mixitup.use(mixitupMultifilter);

jQuery(document).ready(function ($) {

	var noItemsFoundMessage = $('#noItemsFoundMessage');
		$(noItemsFoundMessage).css('display', 'none');

	if($('.products--filter').length > 0) {
		var containerEl = document.querySelector('.products--filter');
		var mixer = mixitup(containerEl, {
			pagination: {
				limit: 12
			},
			multifilter: {
				enable: true
			},

			callbacks: {
				onMixEnd: function(state) {
	   
				  if (state.hasFailed) {  
					  $(noItemsFoundMessage).css('display', 'block');
				  }
	   
				  else {
					  $(noItemsFoundMessage).css('display', 'none');
				  }
				},
			 
			 },
		});
	}

});
