jQuery('.c-navigation__hamburger').on('click', function(){
    jQuery(this).parent('.c-navigation').toggleClass('open');
});

jQuery('.c-menu a[href*=\\#]').on('click', function(event){
    if(this.pathname === window.location.pathname){
        jQuery(this).closest('.c-navigation').removeClass('open');
    }
});

/* Toggle submenu nav */
jQuery('.sub-menu-toggle').on('click', function(){
    var Parent = jQuery(this).parent();
    var matchingUl = jQuery(Parent).find('.sub-menu-first');

    jQuery(this).toggleClass('sub-menu-toggle--active');
    jQuery(matchingUl).slideToggle();
});