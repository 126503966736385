jQuery(document).ready(function($){
    $('.usp-content').first().addClass('active');

    $('.usp').hover(function(e) {
        $('.usp-content').removeClass('active');
        $(this).find('.usp-content').toggleClass("active");
      e.preventDefault();
    });
});

jQuery(document).ready(function($) {
    $('.product-outside').on('click', function(e){
        $('.product-inside').removeClass('active');
        $('.product__inside').removeClass('active');

        $(this).addClass('active');
        $('.product__outside').addClass('active');
    });

    $('.product-inside').on('click', function(e){
        $('.product-outside').removeClass('active');
        $('.product__outside').removeClass('active');

        $(this).addClass('active');
        $('.product__inside').addClass('active');
    });

    
});