jQuery(document).ready(function($){

  if ($(window).width() > 768) {
    $('.usp-content').first().addClass('active');

    $('.usp').hover(function(e) {
        $('.usp-content').removeClass('active');
        $(this).find('.usp-content').toggleClass("active");
      e.preventDefault();
    });
    
  };
});