
jQuery(document).ready(function($) {
    $('.arrow-down').on('click', function(e){
        if($(this).data('target')){
            let target = $(this).data('target');
            $('html, body').animate({
                scrollTop: $(target).offset().top - 140,
            }, 400);
        }
    });
});